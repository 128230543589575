'use strict'

resizeFlg = {}
resizeFlg.pc = true
resizeFlg.sp = true
firstView = ''

spMenu = ()->
  console.log('menu:SP')
  $target = $('.p-header .p-header__inner.js-headroom')
  offset1 = $('.p-h1').outerHeight()
  offset2 = $('.p-h1').outerHeight() + $('.p-header__inner').outerHeight()

  $target.headroom({
    offset: offset1
  })

  $(window).on('scroll',
    ()->
      if ($(this).scrollTop()) > offset2
        $('.p-header__inner').addClass('is-hide')
      else
        $('.p-header__inner').removeClass('is-hide')
  )

  showSubMenu = (e)->
    e.preventDefault()

    $('.js-menu__button').find('a').off('click.smoothScroll')

    $('.js-menu__contents').removeClass('is-active')

    if $(this).hasClass('is-active')
      $('.js-menu__button').removeClass('is-active')
      # $target.removeClass('is-fixed')
      $(this).removeClass('is-active')

      $target.removeClass('is-fixed')

      # if not $target.hasClass('headroom--top')
      #   $target.addClass('is-fixed')

      currentPos = Math.abs(parseInt($('html').css('top').replace('px')) * -1)

      $('html').removeAttr('style').removeClass('is-fixed')

      $(
        if (navigator.userAgent.indexOf('Opera') isnt -1)
        then (
          if document.compatMode is 'BackCompat'
          then 'body'
          else 'html'
        )
        else 'html, body'
      ).animate({
        scrollTop: currentPos
      }, 0)
    else
      $('.js-menu__button').removeClass('is-active')
      # $target.removeClass('is-fixed')
      $(this).addClass('is-active')

      # if not $target.hasClass('headroom--top')
      #   $target.addClass('is-fixed')

      $($(this).find('a').attr('data-menu-target')).addClass('is-active')

      if $('html').attr('style')
        currentPos = Math.abs(parseInt($('html').css('top').replace('px')) * -1)
      else
        currentPos = $(window).scrollTop()

      $('html').addClass('is-fixed')
      $('html').css('top', currentPos * -1)

    if $('.js-menu__contents.is-active').length > 0
      $('.js-menu').addClass('is-active')
      $('.p-h1, .l-header, .js-menu').on('touchmove.noscroll', (e)->
        e.preventDefault()
      )
    else
      $('.p-h1, .l-header, .js-menu').off('touchmove.noscroll')

    $('.js-menu').on('touchmove.noscroll', (e)->
      e.stopPropagation()
    )

    if currentPos > 0
      headerPos = $('.p-header__inner').outerHeight()
    else
      headerPos = ($('.p-h1').offset().top + $('.p-h1').outerHeight()) + $('.p-header__inner').outerHeight()
    $('.js-menu').css('top', headerPos)

    $('.js-menu').on('transitionend', (e)->
      if $('.js-menu__contents.is-active').length is 0
        $(this).removeClass('is-active').removeAttr('style')
    )

  $('.js-menu__button').on('click.menu', showSubMenu)

pcMenu = ()->
  console.log('menu:PC')
  $('.p-header .p-header__nav.js-headroom').headroom({
    offset: $('.p-h1').outerHeight() + $('.p-header__inner').outerHeight()
  })

if window.matchMedia('(max-width:750px)').matches
  # SP
  firstView = 'SP'

  spMenu()
else
  # PC
  firstView = 'PC'

  pcMenu()

$(window).on('resize',
  ()->
    if window.matchMedia('(max-width:750px)').matches
      if firstView is 'PC' and resizeFlg.sp is true or
      firstView is false and resizeFlg.sp is true
      # PC から SP へ

        $('.p-header .p-header__nav.js-headroom').headroom('destroy')

        spMenu()

        resizeFlg.pc = true
        resizeFlg.sp = false
        firstView = false
    else
      if firstView is 'SP' and resizeFlg.pc is true or
      firstView is false and resizeFlg.pc is true
      # SP から PC へ

        $('.p-header .p-header__inner.js-headroom').headroom('destroy')

        $('.js-menu__button').off('click.menu')

        pcMenu()

        resizeFlg.pc = false
        resizeFlg.sp = true
        firstView = false
)

document.querySelector('.js-menu').addEventListener('click', (e)->
  e.stopPropagation()

  console.log(e.currentTarget)

  e.currentTarget.querySelectorAll('.js-menu__contents').forEach((elem, index)->
    if elem.classList.contains('is-active')
      document.querySelector('.'+elem.id.replace('menu-', '')+'.js-menu__button').dispatchEvent(new Event('click'))
  )
)
